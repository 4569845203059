import Axios from "axios"
import { message, Modal } from 'ant-design-vue'
import router from "../router"


//接口地址
const APIURL = process.env.VUE_APP_APIURL;
//分页每页行数可选项
const PAGESIZES = ['10', '20', '30', '40', '50'];
//默认每页行数
const PAGESIZE = 20;

//滚动到错误信息
function scrollIToErrInfo(e) {
    e.$nextTick(() => {
        let myInp = document.getElementsByClassName("is-error");
        if (myInp.length > 0) {
            myInp[0].scrollIntoView();
        }
    });
}

//设置Page属性值
function setPageValue(page, atrrName, attrValue) {
    let propertyArr = atrrName.split(".");
    let dataValue = page;
    let dataKey = "";
    let dataKey2 = [];
    let attrLenth = propertyArr.length;
    for (let i = 0; i < attrLenth; i++) {
        dataKey = propertyArr[i];
        dataKey2 = dataKey.split('[');
        for (let j = 0; j < dataKey2.length; j++) {
            //最后一个属性
            if (i == attrLenth - 1 && j == dataKey2.length - 1) {
                if (j == 0)
                    dataValue[dataKey2[j]] = attrValue;
                else
                    dataValue[dataKey2[j].replace("]", "")] = attrValue;
            }
            else {
                if (j == 0)
                    dataValue = dataValue[dataKey2[j]];
                else
                    dataValue = dataValue[dataKey2[j].replace("]", "")];
            }
        }
    }
}

//弹出消息
function alert(title, msg, buttonText, success) {
    if (title == "") title = "提示";
    if (buttonText == "") buttonText = "确定";
    Modal.confirm({
        title: title,
        content: msg,
        okText: buttonText,
        onOk() {
            if (success && success != null) success();
        }
    });

}
//确认消息
function confirm(title, msg, confirmButtonText, cancelButtonText, success, cancel) {
    if (title == "") title = "操作确认";
    if (confirmButtonText == "") confirmButtonText = "确定";
    if (cancelButtonText == "") cancelButtonText = "取消";
    Modal.confirm({
        title: title,
        content: msg,
        okText: confirmButtonText,
        onOk() {
            if (success && success != null) success();
        },
        cancelText: cancelButtonText,
        onCancel() {
            if (cancel && cancel != null) cancel();
        }
    });
}
//显示信息
function showMsg(msg, type = "error") {
    switch (type) {
        case "success":
            message.success(msg);
            break;
        case "error":
            message.error(msg);
            break;
        case "warning":
            message.warning(msg);
            break;
        default:
            message.error(msg);
    }
}

//判断接口结果
function checkIResult(res, msgBol) {
    if (!res.code) {
        if (msgBol) {
            showMsg(res);
        }
        return false;
    }
    if (res.code == 1) return true;
    if (msgBol) {
        processMsg(res);
    }
    return false;
}
//请求错误
function requestErr(msg) {
    message.error("请求失败，请稍后再试！" + msg);
}
//处理接口信息
function processMsg(res) {
    //token失效
    if (res.errcode == 2) {
        tokenInvalid();
        return;
    }
    //权限错误
    if (res.errcode == 3) {
        noPower();
        return;
    }
    //首次登录修改密码不提示
    if (res.errcode == 5) {
        return;
    }
    //其他错误
    showMsg(res.errmsg);
}
//无操作权限
function noPower() {
    message.warning("您没有此操作的权限，请联系管理员。");
    router.replace("/noPower");
}
//token已失效
let ifShowTokenInvalid = false;
function tokenInvalid() {
    deleteAuth();
    message.warning("您的登录状态已过期，请重新登录。");
    router.replace("/login");
}
//判断是否登录
function ifLogin() {
    let auth = localStorage["auth"];
    let success = false;
    let data;
    if (auth && auth != "") {
        let authObj = JSON.parse(auth);
        if (authObj.token != "") success = true;
        data = {
            success: success,
            token: authObj.token,
            userName: authObj.userName,
            userLoginName: authObj.userLoginName
        }
    }
    else {
        data = {
            success: success,
            token: "",
            userName: "",
            userLoginName: ""
        };
    }
    return data;
}
//写入登录信息
function login(data) {
    let authObj = {
        token: data.token,
        userName: data.userName,
        userLoginName: data.userLoginName
    };
    localStorage["auth"] = JSON.stringify(authObj);
}
//删除登录信息
function deleteAuth() {
    let auth = ifLogin();
    let authObj = {
        token: "",
        userName: auth.userName,
        userLoginName: auth.userLoginName,
    };
    localStorage["auth"] = JSON.stringify(authObj);
}

//请求数据（异步）
function request(page, url, method = "get", data = {}, requesting, success, fail) {
    let requestingType = "";
    if (requesting) {
        requestingType = typeof requesting;
        requestingType = requestingType.toString();
    }
    method = method.toLocaleLowerCase();

    let config = {
        url: url,
        method: method.toLocaleLowerCase(),
        data: data,
        headers: {
            'token': ifLogin().token
        }
    };
    Axios(config).then(res => res.data).then(res => {
        if (requestingType != "") {
            if (requestingType == "string") {
                setPageValue(page, requesting, false);
            } else {
                
            }
        }
        //操作成功
        if (res.code == 1) {
            if (success) success(res);
            return;
        }
        if (fail) fail(res);
        processMsg(res);
    }).catch(res => {
        if (requestingType != "") {
            if (requestingType == "string") {
                setPageValue(page, requesting, false);
            } else {
                
            }
        }
        //请求失败
        requestErr(res);
    });
}
//请求数据（同步）
function requestSync(url, method = "get", data = {}) {
    method = method.toLocaleLowerCase();
    let config = {
        url: url,
        method: method.toLocaleLowerCase(),
        data: data,
        headers: {
            'token': ifLogin().token
        }
    };
    return Axios(config).then(res => res.data);
}

export default {
    APIURL,
    PAGESIZES,
    PAGESIZE,

    scrollIToErrInfo,

    alert,
    confirm,
    showMsg,

    checkIResult,
    ifLogin,
    login,
    deleteAuth,

    request,
    requestSync,
    requestErr,
    processMsg
}
