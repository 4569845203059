const actions = {

}

const mutations = {
    // 表单重新获取
    SET_TABLE_REQUEST(state){
        state.table_action_request = !state.table_action_request
    }
}

const state = {
    // 表单是否重新获取
    table_action_request: false
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

