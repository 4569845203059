// 核心包引用
import { createStore } from "vuex"
// 引用模块文件
import app from "./modules/app"


export default createStore({
  state: {
    netRegion: '',
    employeeInfo: '',
    deviceGlobalList: [],
    visitedViews: [], // 用于存储已访问过的视图
    deviceId: 0,//设备ID
    deviceIdAdmin:0,//有管理端的设备ID
  },
  mutations: {
    setDeviceId(state, deviceId) {
      state.deviceId = deviceId
    },
    setDeviceIdAdmin(state, deviceIdAdmin) {
      state.deviceIdAdmin = deviceIdAdmin
    },
    setNetRegion(state, netRegion) {
      state.netRegion = netRegion
    },
    setEmployeeInfo(state, employeeInfo) {
      state.employeeInfo = employeeInfo
    },
    setVisitedView(state, visitedViews) {
      state.visitedViews = visitedViews
    },
    setDeviceGlobalList(state, deviceGlobalList) {
      state.deviceGlobalList = deviceGlobalList
    },
    addVisitedView(state, view) {
      const parts2 = view.path.split('/');

      //console.log(view)
      if (parts2.length >= 5) {
        view.key = parts2.slice(0, 4).join('/');
      }
      else {
        view.key = view.path
      }
      // 添加已访问的视图
      //if (state.visitedViews.some(v => v.path === view.path)) return;
      for (let i = 0; i < state.visitedViews.length; i++) {
        const path1 = state.visitedViews[i].path;
        const parts1 = path1.split('/');

        if (state.visitedViews[i].path === view.path) {
          return
        }

        const hasThreeParts1 = parts1.length >= 5;
        const hasThreeParts2 = parts2.length >= 5;

        if (hasThreeParts1 && hasThreeParts2) {
          const prefix1 = parts1.slice(0, 4).join('/');
          const prefix2 = parts2.slice(0, 4).join('/');
          if (prefix1 === prefix2) {
            return
          }
        }

      }

      state.visitedViews.push(view);
    },
    removeVisitedView(state, view) {
      // 移除已访问的视图
      const index = state.visitedViews.findIndex(v => v.key === view);
      if (index !== -1) {
        state.visitedViews.splice(index, 1);
      }
    },
    removeVisitedViewList(state, view) {
      // 移除已访问的视图列表
      //const index = state.visitedViews.findIndex(v => v.path === view);
      //console.log("index",index)
      state.visitedViews = state.visitedViews.filter(itemA =>
        !view.some(itemB => itemA.key === itemB.key)
      );
    },
    clearVisitedView(state) {
      // 清空已访问的视图列表
      state.visitedViews = [];
    },
  },
  getters: {
    getNetRegion: state => state.netRegion,
    getEmployeeInfo: state => state.employeeInfo
  },
  actions: {
    setVisitedView({ commit }, view) {
      commit('setVisitedView', view);
    },
    addVisitedView({ commit }, view) {
      commit('addVisitedView', view);
    },
    removeVisitedView({ commit }, view) {
      commit('removeVisitedView', view);
    },
    removeVisitedViewList({ commit }, view) {
      commit('removeVisitedViewList', view);
    },
    clearVisitedViews({ commit }) {
      commit('clearVisitedView');
    },
    setDeviceGlobalList({ commit }) {
      commit('setDeviceGlobalList');
    },
    setDeviceId({ commit }) {
      commit('setDeviceId');
    },
    setDeviceIdAdmin({ commit }) {
      commit('setDeviceIdAdmin');
    },
  },
  modules: {
    app
  }
})
