const validatorConfig = {
  //【整数+小数】(小数最多保留两位小数)（包含正数、零、负数）
  floatNum:/^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/,
   //【正整数】（包含零）
  IntNumZero:/^[+]{0,1}(\d+)$/,
   //【正整数】（不包含零）
  IntNum:/^[1-9]\d*$/,
  //名称
  name: [
    { required: true, message: '请输入名称', trigger: 'change' },
    { min: 2, max: 128, message: '长度限制为2～128个字符', trigger: 'change' }
  ],
  //规格
  specification: [
    // { required: true, message: '请输入规格', trigger: 'change' },
    { min: 2, max: 128, message: '长度限制为2～128个字符', trigger: 'change' }
  ],
  //参考成本
  referenceCost: [
    { required: true, message: '请输入参考成本', trigger: 'change' },
    { pattern: /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/, message: '请输入正数，小数保留两位', trigger: 'change' },
    // { pattern: /^[1-9]\d*$/, message: '参考成本为正整数', trigger: 'change' },
  ],
  //价格
  priceRules:[
    {
      pattern: /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/,
      message: '请输入正数，小数保留两位',
      trigger: 'change',
    },
    { required: true, 
      message: '请输入价格', 
      trigger: 'change' 
    },
  ],
  numberData:[
    { required: true, 
      message: '请输入数量', 
      trigger: 'change' 
    },
    {
      pattern: /^[1-9]\d*$/,
      message: '请输入正整数',
      trigger: 'change',
    }
  ],
  // 接口运营商编码
  carrierRules: [
    { required: true, message: '请输入接口运营商编码', trigger: 'change' },
    // { min: 2, max: 128, message: '长度限制为2～128个字符', trigger: 'change' }
  ],
  // 接口签名
  signatureRules: [
    { required: true, message: '请输入接口签名', trigger: 'change' },
    // { min: 2, max: 128, message: '长度限制为2～128个字符', trigger: 'change' }
  ],
  // 接口配置
  interfaceConfigRules: [
    { required: true, message: '请选择接口配置', trigger: 'change' },
    { pattern: /^[1-9]\d*$/, message: '请选择接口配置', trigger: 'change' },
  ],
}
export default validatorConfig