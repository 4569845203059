import { createApp} from 'vue'
import App from './App.vue'
import router from "./router"
import store from './store'
import * as echarts from 'echarts';
import dayjs from "dayjs";
import PubTool from './plugins/PubTool'
import validatorConfig from './plugins/validator'
import baseConfig from './plugins/baseConfig'
import * as filters from './plugins/filter'
const Vue = createApp(App);

Vue.config.globalProperties.$echarts = echarts;
Vue.config.globalProperties.$dayjs = dayjs;
Vue.config.globalProperties.$tool = PubTool;
Vue.config.globalProperties.$validatorConfig = validatorConfig;
Vue.config.globalProperties.$baseConfig = baseConfig
Vue.config.globalProperties.$filters = filters

// antdui
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import './assets/fontIcon/fonticontop/iconfont.css'
import './assets/fontIcon/fonticon/iconfont.css'
import './assets/fontIcon/fontNew/iconfont.css'

Vue
.use(router)
.use(store)
.use(Antd)
.mount("#app")

