import { createRouter, createWebHistory } from "vue-router";
import { message } from "ant-design-vue";
import store from './store'
const routes = [
  {
    path: '/',
    name: '列表',
    component: () => import('@/views/list/index.vue'),
    meta: { title: '列表', auth: true, last: '列表', keepAlive: true, keepAlivetitle: '列表', menuTitle: '列表' },
  },
  {
    path: '/test',
    name: '测试',
    component: () => import('@/views/list/test.vue'),
    meta: { title: '测试列表', auth: true, last: '测试列表', keepAlive: true, keepAlivetitle: '测试列表', menuTitle: '测试列表' },
  }

]

const router = new createRouter({
  history: createWebHistory(),
  routes
})



export default router
