<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
export default {
  name: "App",
  components: {
  },
  data() {
    return {
      locale: zhCN,
    };
  }
};
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #252631;
  font-size: 12px;
  height: 100%;
  background:#f0f2f5;
}
* {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}
ol,
ul,
dl,
p {
  margin-bottom: 0;
}
p {
  margin-bottom: 0 !important;
}
body{
	
}
</style>
<style lang="less">
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  font-size: 24px !important;
  min-width: auto;
  line-height: 30px !important;
  margin: auto !important;
}
.ant-menu-sub .ant-menu-item > a {
  color: @text-color !important;
}
.ant-tabs-dropdown-menu-item-remove {
  position:absolute;
  top:5px;
  right:10px;
  font-size:9px !important;
}
.ant-tabs-dropdown-menu-item{
  position:relative;
  padding:0 20px;
}
.ant-tabs-dropdown-menu-item >span{
  padding-right:20px;
}
</style>
<style lang="less">
@import url("./assets/less/components/ant-breadcrumb.less");
@import url("./assets/less/components/model.less");
@import url("./assets/less/components/table.less");
@import url("./assets/less/drawer.less");
@import url("./assets/less/components/form.less");
@import url("./assets/less/components/tabs.less");
a {
  color: @primary-color;
}
.ant-layout {
  height: calc(100% - 1px);
}

</style>
