const baseConfig = {
  //是否默认模板
  ifDefaultTemplateData:{
    0:'否',
    1:'是'
  },
  ifDefaultTemplateList:[
    {
      value:0,
      name:'否'
    },
    {
      value:1,
      name:'是'
    }
  ],
  //【正整数】（不包含零）
  IntNum:/^[1-9]\d*$/,
  //【正整数】（包含零）
  IntNumZero:/^[+]{0,1}(\d+)$/,

  // 0-65535的整数
  IntNum0_65535:/^(6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{0,3}|0)$/,

  // 【非空数组】
  notEmptyArray:/^\[.+\]$/,
  
  protocolList:[
    {
      value:-1,
      name:'协议'
    },
    {
      value:'in_udp_pps_before',
      name:'UDP'
    },
    {
      value:'in_icmp_pps_before',
      name:'ICMP'
    },
    {
      value:'in_tcp_syn_pps_before',
      name:'TCP(SYN)'
    },
    {
      value:'in_tcp_other_pps_before',
      name:'TCP(其他)'
    },
    {
      value:'in_other_pps_before',
      name:'Other'
    },
  ],
  typeList:[
    {
      value:0,
      name:'类型'
    },
    {
      value:1,
      name:'静态'
    },
    {
      value:2,
      name:'动态 '
    }
  ],
  typeListMap:{
    0:'类型',
    1:'静态',
    2:'动态',
  },
  listStateListMap:{
    0:'停用',
    1:'启用'
  },
  listStateList: [
    {
      value: -1,
      name: '状态'
    },
    {
      value: 0,
      name: '停用'
    },
    {
      value: 1,
      name: '启用'
    },
  ],
  timeUnitList: [
    {
      value: 0,
      name: '秒'
    },
    {
      value: 1,
      name: '分'
    },
    {
      value: 2,
      name: '时'
    },
    {
      value: 3,
      name: '天'
    },
  ],
  actionList: [
    {
      value: 0,
      name: '放行'
    },
    {
      value: 1,
      name: '拦截'
    },
  ],
  stateList: [
    {
      value: -1,
      name: '状态'
    },
    {
      value: 0,
      name: '禁用'
    },
    {
      value: 1,
      name: '启用'
    },
  ],
  accessStateList: [
    {
      value: -1,
      name: '接入状态'
    },
    {
      value: 0,
      name: '禁用'
    },
    {
      value: 1,
      name: '启用'
    },
  ],
  ifAllowIPAccessList: [
    {
      value: 0,
      name: '拦截'
    },
    {
      value: 1,
      name: '忽略'
    },
  ],
  handdleType:[
    {
      value: 0,
      name: '手动'
    },
    {
      value: 1,
      name: '自动'
    },
  ],
  fhProtocolList:[
    {
      value:40021,
      name:'IP'
    },
    {
      value:40022,
      name:'UDP'
    },
    {
      value:40023,
      name:'TCP'
    },
    {
      value:40024,
      name:'ICMP'
    },
    {
      value:40025,
      name:'Other'
    },
  ],
  eventList: [
    {
      value: -1,
      name: '事件'
    },
    {
      value: 1,
      name: 'down'
    },
    {
      value: 2,
      name: 'up'
    },
  ],
  restartTypeList: [
    {
      value: -1,
      name: '重启类型'
    },
    {
      value: 1,
      name: '操作系统'
    },
    {
      value: 2,
      name: '进程'
    },
  ],
  templateTypeList:[
    {
      value: 0,
      name: '手动'
    },
    {
      value: 1,
      name: '订阅'
    },
    {
      value: 2,
      name: '自动'
    },
  ],
  templateTypeListMap:{
    0:'手动',
    1:'订阅',
    2:'自动',
  },
}

export default baseConfig
