import moment from 'moment'
export const nToCina = (num) => {
  if (!num) return ''
  if (!/^\d*(\.\d*)?$/.test(num)) {
    return ''
  }
  var AA = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']

  var BB = ['', '十', '百', '千', '万', '亿', '点', '']
  var a = ('' + num).replace(/(^0*)/g, '').split('.')
  var k = 0
  var re = ''

  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re
        break

      case 4:
        if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0])) {
          re = BB[4] + re
        }
        break

      case 8:
        re = BB[5] + re
        BB[7] = BB[5]
        k = 0
        break
    }

    if (k % 4 === 2 && a[0].charAt(i + 2) !== 0 && a[0].charAt(i + 1) === 0) {
      re = AA[0] + re
    }

    if (a[0].charAt(i) !== 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re
    k++
  }

  if (a.length > 1) {
    // 加上小数部分(如果有小数部分)

    re += BB[6]

    for (let i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)]
  }
  return re
}
export const distFilter = (value, arr) => {
  if (!value) return ''
  let t = arr.filter(e => {
    return e.value === value
  })
  if (t.length === 0) return ''
  return t[0].name
}
export const formatAmountFilter = (num) => {
  if (!num) {
    num = 0
  }
  return '¥' + num.toFixed(2)
}
export const timeFilter = (time) => {
  if (time == '0001-01-01T00:00:00' || time =='1900-01-01T00:00:00' || time =='1900-01-01 00:00:00'|| time =='1900/01/01 00:00:00') {
    return '--'
  } else if (time !== undefined) {
    return moment(new Date(time)).format('yyyy-MM-DD HH:mm')
  } else {
    return ''
  }
}
export const toLocaleFilter = (num) => {
  if (num !== undefined) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
export const numFilter = (num) => {
  if (num !== undefined) {
    if (num === 1) {
      return '一'
    }
    if (num === 2) {
      return '二'
    }
    if (num === 3) {
      return '三'
    }
    if (num === 4) {
      return '四'
    }
    if (num === 5) {
      return '五'
    }
    if (num === 6) {
      return '六'
    }
    if (num === 7) {
      return '七'
    }
    if (num === 8) {
      return '八'
    }
    if (num === 9) {
      return '九'
    }
    if (num === 10) {
      return '十'
    }
  } else {
    return ''
  }
}
export const statusFilter = (num) => {
  if (num !== undefined) {
    if (num === 0) {
      return '未支付'
    }
    if (num === 1) {
      return '已支付'
    }
    if (num === 2) {
      return '作废'
    }
    if (num === 3) {
      return '已删除'
    }
  } else {
    return ''
  }
}

//账单状态
export const orderStatusFilter = (num) => {
  if (num !== undefined) {
    if (num === 1) {
      return '待支付'
    }
    if (num === 2) {
      return '支付成功'
    }
    if (num === 3) {
      return '支付失败'
    }
  } else {
    return ''
  }
}

export const billStatusFilter = (num) => {
  if (num !== undefined) {
    if (num === 1) {
      return '待支付'
    }
    if (num === 2) {
      return '支付成功'
    }
    if (num === 3) {
      return '支付失败'
    }
  } else {
    return ''
  }
}

export const billTypeFilter = (num) => {
  if (num !== undefined) {
    if (num === 1) {
      return '收入'
    }
    if (num === 2) {
      return '支出'
    }
  } else {
    return ''
  }
}

export const repairFilter = (num) => {
  if (num !== undefined) {
    if (num === 1) {
      return '已提交'
    }
    if (num === 2) {
      return '已受理'
    }
    if (num === 3) {
      return '处理中'
    }
    if (num === 4) {
      return '转上级'
    }
    if (num === 5) {
      return '已处理'
    }
    if (num === 6) {
      return '已完成'
    }
  } else {
    return ''
  }
}
